import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const index1 = () => {
    return (
        <React.Fragment>
            <section className="bg-home-half" id="home">
                <div className="home-center">
                    <div className="home-desc-center">
                        <Container>
                            <Row>
                                <Col lg={9} >
                                    <h5 className="home-small-title text-uppercase mb-4">We create the web</h5>
                                    <h2 className="home-title mb-4">Create amazing designs with Larix</h2>
                                    <p className="home-desc">Join us in this exciting journey through the internet</p>
                                    <div className="pt-4">
                                        <Link to="#" className="btn btn-custom">View More <i className="mdi mdi-arrow-right ms-2"></i></Link>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default index1;