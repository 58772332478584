import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <React.Fragment>
            <section id="footer" className="footer bg-dark">
                <Container>
                    <Row>
                        <Col lg={4}>
                            <div className="footer-menu">
                                <h3 className="mb-4 text-uppercase text-white"><i className="mdi mdi-chart-donut-variant"></i> Larix</h3>
                                <div className="text-white d-flex gap-1">

                                </div>
                            </div>
                        </Col>

                        <Col lg={2}>

                        </Col>

                        <Col lg={2}>

                        </Col>

                        <Col lg={4}>
                            <div className="footer-menu">
                                <h5 className="mb-4 text-uppercase">Contact us</h5>
                                <div className="footer-support">Industry type: advertising and marketing</div>
                                <div className="footer-support">Company name: 成都房启家科技有限公司</div>
                                <div className="footer-support">Social Credit Code: 91510106MADTWLXF1C</div>
                                <div className="footer-support">Address: 四川省成都市金牛区抚琴西路181号22栋4楼1号附18号(申报承诺)</div>
                                <div className="footer-support">Postal code: 610036</div>
                                <div className="footer-support">English name of the city : Chengdu</div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <div className="text-center mt-5">
                                <p className="mb-0">{new Date().getFullYear()} © Larix.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
}

export default Footer;