import Index1 from "../pages/index1";
import Index2 from "../pages/index2";
import Index3 from "../pages/index3";
import Index4 from "../pages/index4";
import Index5 from "../pages/index5";

interface RouteProps {
    path: string;
    component?: any;
    exact?: boolean;
    isIndex?: boolean
}

const Routes: Array<RouteProps> = [
    { path: '/', component: <Index1 /> },
    { path: '/index-1', component: <Index1 /> },
    { path: '/index-2', component: <Index2 /> },
    { path: '/index-3', component: <Index3 /> },
    { path: '/index-4', component: <Index4 />, isIndex: true },
    { path: '/index-5', component: <Index5 /> },
]

export default Routes;