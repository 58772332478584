import React from "react";
import { Route, Routes } from "react-router-dom";
import routes from "./allRoutes";
import Layout from "../Layout";

const Index = () => {
    return (
        <React.Fragment>
            <Routes>
                <Route>
                    {
                        routes.map((route: any, idx: number) => (
                            <Route path={route.path} key={idx} element={
                                <Layout isIndex={route.isIndex}>
                                    {route.component}
                                </Layout>
                            } />
                        ))
                    }
                </Route>
            </Routes>
        </React.Fragment>
    );
}

export default Index;