import React from "react";
import { featuresData } from "./comon/data";
import { Col, Container, Row } from "react-bootstrap";

const Services = ({ isIndex }: any) => {
    return (
        <React.Fragment>
            <section className={`section ${isIndex ? "mt-5" : ""}`} id="service">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={7}>
                            <div className="text-center">
                                <h4 className="title-heading">Our solution for your business</h4>
                                <p className="title-desc text-muted mt-3">
                                    We craft digital, graphic and dimensional thinking, to create category leading brand experiences that
                                    have meaning and add a value.
                                </p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="pt-5">
                        {(featuresData || []).slice(0, 3).map((feature:any, index:number) => (
                            <Col lg={4} key={index}>
                                <div className="features-box mt-4">
                                    <div className="d-flex">
                                        <div className="features-icon text-center flex-shrink-0">
                                            <i className={feature.icon}></i>
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h5 className="mt-0 f-19">{feature.title}</h5>
                                            <p className="text-muted">{feature.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                    <Row className="pt-3">
                        {(featuresData || []).slice(3, 6).map((feature:any, index:number) => (
                            <Col lg={4} key={index}>
                                <div className="features-box mt-4">
                                    <div className="d-flex">
                                        <div className="features-icon text-center flex-shrink-0">
                                            <i className={feature.icon}></i>
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h5 className="mt-0 f-19">{feature.title}</h5>
                                            <p className="text-muted">{feature.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default Services;