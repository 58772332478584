import team1 from "../../../assets/images/team/1.jpg";
import team2 from "../../../assets/images/team/2.jpg";
import team3 from "../../../assets/images/team/3.jpg";
import team4 from "../../../assets/images/team/4.jpg";


const data = [
    {
        value: "128+",
        label: "Projects"
    },
    {
        value: "89",
        label: "Clients"
    },
    {
        value: "15842",
        label: "Members"
    },
    {
        value: "125",
        label: "Employee"
    }
];

const teamMembers = [
    {
        imageSrc: team1,
        name: "Jane Thornton",
        role: "CEO/Founder"
    },
    {
        imageSrc: team2,
        name: "Wade Roberts",
        role: "CTO/Co-Founder"
    },
    {
        imageSrc: team3,
        name: "Roberta Morales",
        role: "Web Designer"
    },
    {
        imageSrc: team4,
        name: "Mia Smithson",
        role: "Web Developer"
    }
];
export { data, teamMembers };