import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { data, teamMembers } from "./comon/data";

const About = () => {
    return (
        <React.Fragment>
            <section className="section" id="about">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={12}>
                            <div className="about-title mx-auto text-center">
                                <h2 className="fw-normal">A Digital web studio creating stunning & Engaging online Experiences </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mt-5" id="counter">
                        {(data || []).map((item: any, index: number) => (
                            <Col lg={3} key={index}>
                                <div className={`text-muted text-center ${index === data.length - 4 ? "" : " about-border-left"} `}>
                                    <h3 className="counter-value fw-normal">{item.value}</h3>
                                    <p>{item.label}</p>
                                </div>
                            </Col>
                        ))}
                    </Row>
                    <Row className="mt-5">
                        {(teamMembers || []).map((member: any, index: number) => (
                            <Col lg={3} key={index}>
                                <div className="mt-3">
                                    <img
                                        src={member.imageSrc}
                                        alt=""
                                        className="img-fluid team-member mx-auto d-block rounded-circle img-thumbnail"
                                    />
                                    <div className="text-center mt-3">
                                        <p className="fw-medium mb-0">{member.name}</p>
                                        <p className="text-muted mb-0">{member.role}</p>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>
            <section className="section bg-cta">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8}>
                            <h3 className="text-center">Ready to start your next
                                web project now?</h3>
                            <p className="cta-desc text-center mt-3">When a good idea comes, you know, part of my job is to move it around, just see what people think, get people talking about it.</p>
                            <div className="text-center mt-4">
                                <Link to="#" className="btn btn-custom">Get Started</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
}

export default About;