const featuresData = [
    {
      icon: 'uil uil-water',
      title: 'Natural Flow',
      description:
        'Our websites are designed with a natural flow, ensuring smooth navigation and an intuitive user experience. Visitors effortlessly move through your content, maximizing engagement and conversions.',
    },
    {
      icon: 'uil uil-dropbox',
      title: 'Awesome Design',
      description:
        'We craft visually stunning websites that captivate your audience from the moment they land on your page. Every design is a perfect blend of creativity and functionality.',
    },
    {
      icon: 'uil uil-focus-target',
      title: 'Easy to customize',
      description:
        'Built for flexibility, our websites are easy to customize, allowing you to make updates and changes effortlessly. No coding experience required—simply tailor the design to your evolving needs.',
    },
    {
      icon: 'uil uil-fire',
      title: 'Hot Looks',
      description:
        'Stay ahead of the trends with sleek, modern designs that make your website stand out. Our sites are visually striking and built to impress, giving your brand the bold online presence it deserves.',
    },
    {
      icon: 'uil uil-game-structure',
      title: 'Unrivaled Quality',
      description:
        'We guarantee top-notch quality in every aspect of your website, from design to functionality. Built with precision, our websites are reliable, fast, and crafted to the highest standards.',
    },
    {
      icon: 'uil uil-flower',
      title: 'Clean Design',
      description:
        'Experience the elegance of simplicity with our clean design approach. We create uncluttered, minimalist layouts that keep your message clear and impactful, ensuring a seamless user experience.',
    },
  ];

  export default featuresData;