import React, { useEffect, useState } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";

const Header = () => {
    const [activeLink, setActiveLink] = useState<any>();
    const [isSticky, setIsSticky] = useState('');

    window.onscroll = function () {
        let scrollTop = document.documentElement.scrollTop;
        if (scrollTop > 50) {
            setIsSticky("nav-sticky");
        } else {
            setIsSticky("");
        }
    };


    useEffect(() => {
        const activation = (event: any) => {
            const target = event.target.getAttribute('href');
            const navitem = event.target.parentElement;
            const element = document.querySelector(target);

            if (element) {
                navitem.classList.add('active');
                setActiveLink(navitem);
                if (activeLink && activeLink !== navitem) {
                    activeLink.classList.remove('active');
                }
            }
        };

        const defaultLink = document.querySelector('.navbar-nav li.active');
        if (defaultLink) {
            defaultLink?.classList.add("active")
            setActiveLink(defaultLink)
        }

        const links = document.querySelectorAll('.navbar-nav a');
        links.forEach((link) => {
            link.addEventListener('click', activation);
        });

        return () => {
            links.forEach((link) => {
                link.removeEventListener('click', activation);
            });
        };
    }, [activeLink]);

    return (
        <React.Fragment>
            <Navbar expand="lg" className={`fixed-top navbar-custom sticky ${isSticky}`}>
                <Container>
                    <Navbar.Brand className="logo" href="/"><i className="mdi mdi-chart-donut-variant"></i> Larix</Navbar.Brand>
                    <Navbar.Toggle type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="mdi mdi-menu"></i>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="navbarSupportedContent">
                        <Nav as="ul" className="ms-auto mb-2 mb-lg-0">
                            <li className="nav-item active">
                                <Nav.Link href="#home">Home</Nav.Link>
                            </li>
                            <li className="nav-item">
                                <Nav.Link href="#service">Services</Nav.Link>
                            </li>
                            <li className="nav-item">
                                <Nav.Link href="#about">About</Nav.Link>
                            </li>
                            <li className="nav-item">
                                <Nav.Link href="#footer">Contact</Nav.Link>
                            </li>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </React.Fragment>
    );
}

export default Header;