import React from "react";
import { Carousel, Col, Container, Row } from "react-bootstrap";

//img
import homebg from "../../assets/images/home/home-bg.jpg";
import homebg2 from "../../assets/images/home/home-bg-2.jpg";
import homebg3 from "../../assets/images/home/home-bg-3.jpg";
import { Link } from "react-router-dom";

const Index5 = () => {
    return (
        <React.Fragment>
            <section className="home-slider" id="home">
                <Carousel id="carouselExampleControls">
                    <Carousel.Item style={{ backgroundImage: `url(${homebg3})` }}>
                        <div className="home-center">
                            <div className="home-desc-center">
                                <Container>
                                    <Row >
                                        <Col lg={12}>
                                            <div className="text-center">
                                                <h5 className="home-small-title text-uppercase text-muted mb-4">Awesome
                                                    Design</h5>
                                                <h2 className="home-title mb-4">We love make things amazing and simple
                                                </h2>
                                                <p className="home-desc text-muted mx-auto">Maecenas class semper class
                                                    semper sollicitudin lectus lorem iaculis imperdiet aliquam
                                                    vehicula tempor auctor curabitur pede aenean ornare.</p>
                                                <div className="text-center pt-4 d-flex gap-1 justify-content-center">
                                                    <Link to="#" className="btn btn-custom me-2">Contact Us</Link>
                                                    <Link to="#" className="btn btn-custom-white">Get Started</Link>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item style={{ backgroundImage: `url(${homebg2})` }}>
                        <div className="home-center">
                            <div className="home-desc-center">
                                <Container>
                                    <Row>
                                        <Col lg={12}>
                                            <div className="text-center">
                                                <h5 className="home-small-title text-uppercase text-muted mb-4">Awesome
                                                    Design</h5>
                                                <h2 className="home-title mb-4">We love make things amazing and simple
                                                </h2>
                                                <p className="home-desc text-muted mx-auto">Maecenas class semper class
                                                    semper sollicitudin lectus lorem iaculis imperdiet aliquam
                                                    vehicula tempor auctor curabitur pede aenean ornare.</p>
                                                <div className="text-center pt-4 d-flex gap-1 justify-content-center">
                                                    <Link to="#" className="btn btn-custom me-2">Contact Us</Link>
                                                    <Link to="#" className="btn btn-custom-white">Get Started</Link>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item style={{ backgroundImage: `url(${homebg})` }}>
                        <div className="home-center">
                            <div className="home-desc-center">
                                <Container>
                                    <Row>
                                        <Col lg={12}>
                                            <div className="text-center">
                                                <h5 className="home-small-title text-uppercase text-muted mb-4">Awesome
                                                    Design</h5>
                                                <h2 className="home-title mb-4">We love make things amazing and simple
                                                </h2>
                                                <p className="home-desc text-muted mx-auto">Maecenas class semper class
                                                    semper sollicitudin lectus lorem iaculis imperdiet aliquam
                                                    vehicula tempor auctor curabitur pede aenean ornare.</p>
                                                <div className="text-center pt-4 d-flex gap-1 justify-content-center">
                                                    <Link to="#" className="btn btn-custom me-2">Contact Us</Link>
                                                    <Link to="#" className="btn btn-custom-white">Get Started</Link>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </section>
        </React.Fragment>
    );
}

export default Index5;