import React from "react";
import Header from "./NavBar";
import Services from "../components/Services";
import Works from "../components/Works";
import Pricing from "../components/Pricing";
import About from "../components/About";
import Blog from "../components/Blog";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

const Layout = (props: any) => {
    return (
        <React.Fragment>
            <Header />
            {props.children}
            <Services isIndex={props.isIndex} />
            {/*<Works />*/}
            {/*<Pricing />*/}
            <About />
            {/*<Blog />*/}
            {/*<Contact />*/}
            <Footer />
        </React.Fragment>
    )
}

export default Layout;