import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Index2 = () => {
    return (
        <React.Fragment>
            <section className="section bg-home" id="home">
                <div className="home-center">
                    <div className="home-desc-center">
                        <Container>
                            <Row className="vertical-content">
                                <Col lg={7} className="mt-3">
                                    <h5 className="home-small-title text-uppercase text-muted mb-4">Awesome Design</h5>
                                    <h2 className="home-title mb-4">Digital solutions that engage, inspire and make you think</h2>
                                    <p className="home-desc mb-50">Our users are impatient. They're probably distracted too. Keep it simple and beautiful, fun and functional. Clean aesthetics supported by a strong concept is what we stand for.</p>
                                    <div className="pt-4 d-flex gap-1">
                                        <Link to="#" className="btn btn-custom me-2">Get Started</Link>
                                        <Link to="#" className="btn btn-custom-white">View More <i className="mdi mdi-arrow-right ms-2"></i></Link>
                                    </div>
                                </Col>

                                <Col lg={5} className="mt-3">
                                    <div className="home-registration-form bg-white p-5">
                                        <h5 className="form-title mb-4 text-center fw-bold">We are offering 14 days
                                            free trial</h5>
                                        <form className="registration-form">
                                            <label className="text-muted">First Name</label>
                                            <Form.Control type="text" id="exampleInputName1" className="mb-4 registration-input-box" />
                                            <label className="text-muted">Last Name</label>
                                            <Form.Control type="text" id="exampleInputName2" className="mb-4 registration-input-box" />
                                            <label className="text-muted">Email</label>
                                            <Form.Control type="email" id="exampleInputEmail1" className="mb-4 registration-input-box" />
                                            <button type="submit" className="btn btn-custom w-100"onClick={(e:any) => e.preventDefault()}>Free Trial</button>
                                        </form>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default Index2;